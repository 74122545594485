const UsDeclarationsKeys = {
  TITLE: "title",
  ID: "id",
  PARTY_ID: "partyId",
  USER_ID: "userId",
  DECLARATION_ID: "declarationId",
  DECLARATION_DESCRIPTION: "declarationDescription",
  LOCKED_BY: "lockedBy",
  LOCKED_DATE: "lockedDate",
  CREATED_DATE: "createdDate",
  UPDATED_DATE: "updatedDate",
  Validations:{
    MAX_LENGTH: "validations.maxLength",
    ALPHANUMERIC: "validations.alphaNumeric",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR: "validations.errMsgAlphaNumericSpecChar"
  }
}

export default UsDeclarationsKeys
