const AceOcnVesselArrivalKeys = {
  ID: "id",
  TITLE: "title",
  MANIFEST_HEADER_ID: "manifestHeaderId",
  CARRIER_SCAC: "carrierScac",
  VESSEL_NAME: "vesselName",
  VOYAGE_NUMBER: "voyageNumber",
  VESSEL_COUNTRY: "vesselCountry",
  PORT_OF_DISCHARGE: "portOfDischarge",
  ARRIVAL_DATE: "arrivalDate",
  ARRIVAL_TIME: "arrivalTime",
  LAST_FOREIGN_PORT: "lastForeignPort",
  NO_OF_BL: "noOfBl",
  ARRIVAL_STATUS: "arrivalStatus",
  RESPONSE_DATE: "responseDate",
  MODE_OF_TRANSPORT: "modeOfTransport",
  ETA: "eta",
  ISSUER_SCAC: "issuerScac",
  PARTY_ID: "partyId",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  CREATE_VESSEL_ARRIVAL: "createVesselArrival",
  EDIT_BUTTON: "editButton",
  EDIT_SENT_RECORD: "editSentRecord",
  DELETE_BUTTON: "deleteButton",
  DELETE_NOT_DRAFT_RECORD: "deleteNotDraftRecord",
  CREATE_VESSEL_ARRIVAL_TAB: "createVesselArrivalTab",
  VIEW_VESSEL_ARRIVAL_TAB: "viewVesselArrivalTab",
  VIEW_VESSEL_ARRIVAL_RESPONSE_TAB: "viewVesselArrivalResponseTab",
  Validations: {
    ARRIVAL_DATE_INVALID: "validations.arrivalDateInvalid",
    ARRIVAL_TIME_INVALID: "validations.arrivalTimeInvalid",
    ERROR_MSG_NUMERIC: "validations.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH: "validations.errMsgMaxLength"
  }
}

export default AceOcnVesselArrivalKeys
