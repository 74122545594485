const ReTriggerSubmissionKeys = {
  TITLE: "title",
  MODULE: "module",
  MENU: "menu",
  FROM_SUBMITTED_DATETIME: "fromSubmittedDatetime",
  TO_SUBMITTED_DATETIME: "toSubmittedDatetime",
  FROM_STATUS: "fromStatus",
  TO_STATUS: "toStatus",
  FROM_MESSAGE_FUNCTION: "fromMessageFunction",
  TO_MESSAGE_FUNCTION: "toMessageFunction",
  TO_AMENDMENT_CODE: "toAmendmentCode",
  TO_MANIFEST_MESSAGE_FUNCTION: "toManifestMessageFunction",
  TO_MANIFEST_AMENDMENT_CODE: "toManifestAmendmentCode",
  FROM_CREATED_DATETIME: "fromCreatedDatetime",
  TO_CREATED_DATETIME: "toCreatedDatetime",
  MODE_OF_SUBMISSION: "modeOfSubmission",
  UPS_MESSAGE: "upsMessage",
  USER_ID: "userId",
  SEARCH_BUTTON: "searchButton",
  SUBMIT_SELECTED_RECORD_BUTTON: "submitSelectedRecordButton",
  SUBMIT_ALL_BUTTON: "submitAllButton",
  CLEAR_BUTTON: "clearButton",
  TRIP_NUMBER: "tripNumber",
  SUBMITTED_DATE: "submittedDate",
  CURRENT_STATUS: "currentStatus",
  MESSAGE_FUNCTION: "messageFunction",
  USER_ID_INCLUDED_CHILD_PARTY: "userIdIncludedChildParty",
  CREATED_DATE: "createdDate",
  SUBMIT_SUCCESS_MESSAGE: "submitSuccessMessage",
  SUBMIT_FAIL_MESSAGE: "submitFailMessage",
  CCN: "ccn",
  SCN: "scn",
  INBOND_NUMBER: "inbondNumber",
  USER_ID_NOT_FOUND: "userIdNotFound",
  ValidationMessage: {
    ERROR_LATER_THAN_FROM_SUBMITTED_DATETIME: "validationMessage.errLaterThanFromSubmittedDatetime",
  },
  EMPTY_SUBMIT_MESSAGE: "emptySubmitMessage",
  PRIMARY_CCN: "primaryCcn",
  PREVIOUS_CCN: "previousCcn",
  CCN_SHORT: "ccnShort",
  CONV_REF_NO: "convRefNo"
}

export default ReTriggerSubmissionKeys
