const NBCICanadaInvoiceKeys = {
  TITLE: "title",
  ID: "id",
  CORP_ID: "corpId",
  INVOICE_NUMBER: "invoiceNumber",
  INVOICE_NUMBER2: "invoiceNumber2",
  UPS_SCS_CLIENT_ID: "upsScsClientId",
  PROBILL_NO: "probillNo",
  INVOICE_DATE: "invoiceDate",
  PURCHASE_ORDER_NUM: "purchaseOrderNum",
  CURRENCEY: "currencey",
  TRIP_VOYAGE_NO: "tripVoyageNo",
  SHIPMENT_DATE: "shipmentDate",
  ENTRY_NUMBER: "entryNumber",
  TRANSACTION_TYPE: "transactionType",
  PORT_OF_EXIT: "portOfExit",
  TRANSPORT_MODE: "transportMode",
  CARRIER_CODE: "carrierCode",
  CARRIER_NAME: "carrierName",
  CCN: "ccn",
  BILL_OF_LADING: "billOfLading",
  GROSS_WEIGHT: "grossWeight",
  GROSS_WEIGHT_UOM: "grossWeightUom",
  NET_WEIGHT: "netWeight",
  NET_WEIGHT_UOM: "netWeightUom",
  MARKS_NUMBERS: "marksNumbers",
  STATUS: "status",
  SERVICE_BEURE_ID: "serviceBeureId",
  LAST_PROCESSED_BY: "lastProcessedBy",
  LAST_PROCESSED_DATE: "lastProcessedDate",
  SUBMISSION_TYPE: "submissionType",
  RESPONSE_DATE_TIME: "responseDateTime",
  REMAINDER_FLAG: "remainderFlag",
  REMAINDER_NOTE: "remainderNote",
  MOD_DATE_TIME: "modDateTime",
  LOCKED_BY: "lockedBy",
  LOCKED_DATE: "lockedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  CREATED_DATE: "createdDate",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecord",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  SELECT_ACTION: "selectAction",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG: "submitErrorMsg",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitRecordDialogContent",
  SUBMIT_RECORD: "submitRecord",
  RESPONSE_TITLE:"responseTitle",
  ACTIVITYLOG_TITLE: "activityLogs",
  LOGIN_USER_ID:"loginUserID",
  FUNCTION_CODE:"FunctionCode",
  AUDIT_DESC:"AuditDesc",
  RESPONSE_TITLE:"inbondResponse",
  BATCH_ID:"batchID",
  CBSA_PROCESSING_DATE:"cbsaProcDate",
  CBP_REF_NO:"cbpRefNo",
  RESPONSE_CODE:"responseCode",
  RESPONSE_TEXT:"responseText",
  RESPONSE_DATE:"responseDate",
  SUBMISSION_TYPE:"submissionType",
  RESPONSE_TAB:"response",
  ERROR_CODE: "responseErrorDetails",
  RECEIVED_DATE: "responseReceivedDate",
  ERRORCODE: "responseErrorCode",
  REJECT_TYPE: "responseRejectType",
  ERROR_DESC: "responseErrorDesc",
  SYSTEM_PROCESSED_DATE_TIME: "responseSystemDate",
  REMARKS: "responseRemarks",
  VIEW: "responseView",
  DOCUMENT_TYPE: "responseDocType",
  MESSAGE_FUNCTION: "responseMessageFunction",
  ERROR_TEXT_LABEL: "errorTextLabel",
  EDIT_INVOICE:"editCanadaInvoice",
  ACTIVITY_LOGS:"activityLogs",
  TEMPLATE_FLAG:"templateFlag",
  TEMPLATE_NAME:"templateName",
  NaftaForm: {
    TITLE: "naftaForm.title",
    ID: "naftaForm.id",
    CORP_ID: "naftaForm.corpId",
    INVOICE_ID: "naftaForm.invoiceId",
    BLANKET_PERIOD_FROM: "naftaForm.blanketPeriodFrom",
    BLANKET_PERIOD_TO: "naftaForm.blanketPeriodTo",
    COMPANY: "naftaForm.company",
    NAFTA_TITLE: "naftaForm.naftaTitle",
    NAFTA_DATE: "naftaForm.naftaDate",
    PARTY_NAME: "naftaForm.partyName",
    CONTACT_TELPH_NUM: "naftaForm.contactTelphNum",
    CONTACT_FAX: "naftaForm.contactFax",
    EMAIL: "naftaForm.email",
    MOD_DATE_TIME: "naftaForm.modDateTime",
    CERTIFIER_TYPE: "naftaForm.certifierType"
  },
  BolForm: {
    TITLE: "bolForm.title",
    ID: "bolForm.id",
    CORP_ID: "bolForm.corpId",
    INVOICE_ID: "bolForm.invoiceId",
    CONTAINER_NO: "bolForm.containerNo",
    FREIGHT_CHARGES: "bolForm.freightCharges",
    PLACE_OF_LADDING: "bolForm.placeOfLadding",
    DELIVERY_INSTRUCTIONS: "bolForm.deliveryInstructions",
    COD_AMOUNT: "bolForm.codAmount",
    CURRENCY: "bolForm.currency",
    DECLARE_VALUE_OF_SHIPMENT: "bolForm.declareValueOfShipment",
    CHARGE_TO: "bolForm.chargeTo",
    RELEASE_VALUE: "bolForm.releaseValue",
    MOD_DATE_TIME: "bolForm.modDateTime"
  },
  InvSummary: {
    TITLE: "invSummary.title",
    ID: "invSummary.id",
    CORP_ID: "invSummary.corpId",
    INVOICE_ID: "invSummary.invoiceId",
    TOTAL_INVOICE: "invSummary.totalInvoice",
    MOD_DATE_TIME: "invSummary.modDateTime",
  },
  InvSummaryReference: {
    TITLE: "invSummaryReference.title",
    ID: "invSummaryReference.id",
    CORP_ID: "invSummaryReference.corpId",
    INVOICE_ID: "invSummaryReference.invoiceId",
    INVOICE_SUMMARY:"invSummaryReference._invSummaryId",
    ADDITION_DEDUCTION_IND: "invSummaryReference.additionDeductionInd",
    ADDITION_DEDUCTION_CODE: "invSummaryReference.additionDeductionCode",
    ADDITION_DEDUCTION_TYPE: "invSummaryReference.additionDeductionType",
    AMOUNT_RATE_PERCENT: "invSummaryReference.amountRatePercent",
    EXPLANATION: "invSummaryReference.explanation",
  },
  ShipmentSummary: {
    TITLE: "shipmentSummary.title",
    INV_SUMMARY: "shipmentSummary._invSummaryId",
    ID: "shipmentSummary.id",
    CORP_ID: "shipmentSummary.corpId",
    SUMMARY_ID: "shipmentSummary.summaryId",
    NO_OF_PKGS: "shipmentSummary.noOfPkgs",
    PKG_TYPE: "shipmentSummary.pkgType",
    PKG_DIM_LENGTH: "shipmentSummary.pkgDimLength",
    PKG_DIM_WIDTH: "shipmentSummary.pkgDimWidth",
    PKG_DIM_HEIGHT: "shipmentSummary.pkgDimHeight",
    PKG_DIM_UOM: "shipmentSummary.pkgDimUom",
    COMMODITY_DESCRIPTION: "shipmentSummary.commodityDescription",
    GROSS_WEIGHT: "shipmentSummary.grossWeight",
    GROSS_WEIGHT_UOM: "shipmentSummary.grossWeightUom",
    FREIGHT_CLASS: "shipmentSummary.freightClass",
    MOD_DATE_TIME: "shipmentSummary.modDateTime"
  },
  PackingList: {
    TITLE: "packingList.title",
    ID: "packingList.id",
    CORP_ID: "packingList.corpId",
    INVOICE_ID: "packingList.invoiceId",
    OTHER_REF_NO: "packingList.otherRefNo",
    RESPONSIBLE_FOR_CUSTOMS: "packingList.responsibleForCustoms",
    INSTRUCTIONS_TO_BROKER: "packingList.instructionsToBroker",
    CONTAINERIZED: "packingList.containerized",
    BILL_CHARGES_TO: "packingList.billChargesTo",
    PORT_OF_LOADING: "packingList.portOfLoading",
    FLIGHT_DETAILS: "packingList.flightDetails",
    PLACE_OF_DELIVERY: "packingList.placeOfDelivery",
    SPECIAL_INSTRUCTIONS: "packingList.specialInstructions",
    MOD_DATE_TIME: "packingList.modDateTime"
  },
  B13aForm: {
    TITLE: "b13aForm.title",
    ID: "b13aForm.id",
    CORP_ID: "b13aForm.corpId",
    INVOICE_ID: "b13aForm.invoiceId",
    BUSINESS_NO: "b13aForm.businessNo",
    EXPORT_LICENSE_NO: "b13aForm.exportLicenseNo",
    DESTINATION_COUNTRY: "b13aForm.destinationCountry",
    TRANSPORT_DOCNO: "b13aForm.transportDocno",
    CUST_PROOF_REPORT_NO: "b13aForm.custProofReportNo",
    GOODS_INSPECTED: "b13aForm.goodsInspected",
    STATUS: "b13aForm.status",
    VESSEL_NAME: "b13aForm.vesselName",
    DATE_OF_EXPORT: "b13aForm.dateOfExport",
    NO_OF_PKGS: "b13aForm.noOfPkgs",
    PKGS_TYPE: "b13aForm.pkgsType",
    CONTAINER_NO: "b13aForm.containerNo",
    FRIEGHT_CHRGS_TO_EXIT: "b13aForm.frieghtChrgsToExit",
    EXPORT_REF_NO: "b13aForm.exportRefNo",
    REASON_FOR_EXPORT: "b13aForm.reasonForExport",
    MOD_DATE_TIME: "b13aForm.modDateTime"
  },
  InvDetails: {
    TITLE: "invDetails.title",
    ID: "invDetails.id",
    CORP_ID: "invDetails.corpId",
    INVOICE_ID: "invDetails.invoiceId",
    SUB_PROBILL_NO: "invDetails.subProbillNo",
    PRODUCT_ID: "invDetails.productId",
    PART_NO: "invDetails.partNo",
    HS_CODE: "invDetails.hsCode",
    COUNTRY_OF_ORIGIN: "invDetails.countryOfOrigin",
    STATE_OF_ORIGIN: "invDetails.stateOfOrigin",
    QTY_INVOICED: "invDetails.qtyInvoiced",
    QTY_UOM: "invDetails.qtyUom",
    UNIT_PRICE: "invDetails.unitPrice",
    DESCRIPTION: "invDetails.description",
    PURCHASE_ORDER_NO: "invDetails.purchaseOrderNo",
    NO_OF_PKGS: "invDetails.noOfPkgs",
    NO_OF_PKGS_UOM: "invDetails.noOfPkgsUom",
    LINE_ITEM_TOTAL: "invDetails.lineItemTotal",
    NET_WEIGHT: "invDetails.netWeight",
    NET_WEIGHT_UOM: "invDetails.netWeightUom",
    GROSS_WEIGHT: "invDetails.grossWeight",
    GROSS_WEIGHT_UOM: "invDetails.grossWeightUom",
    LINE_AMOUNT: "invDetails.lineAmount",
    NAFTA_CRITERIA: "invDetails.naftaCriteria",
    DATE_RANGE_FROM: "invDetails.dateRangeFrom",
    DATE_RANGE_TO: "invDetails.dateRangeTo",
    NET_COST: "invDetails.netCost",
    AUTOFILL: "invDetails.autoFill",
    PRODUCT_ID: "invDetails.productID",
    PRODUCT_DESCRIPTION: "invDetails.productDescription",
    PART_NUMBER: "invDetails.partNumber",
    IS_PRODUCER: "invDetails.isProducer",
    INCLUDE_PROD_NAFATA: "invDetails.includeProdNafata",
    NOT_A_PRODUCER_OF_GOODS: "invDetails.notAProducerOfGoods",
    MOD_DATE_TIME: "invDetails.modDateTime",
    SAVE_FLAG:"invDetails.saveFlag"
  },
  ServicePromotion: {
    TITLE: "servicePromotion.title",
    INV_DETAILS: "servicePromotion._invDetailsId",
    ID: "servicePromotion.id",
    CORP_ID: "servicePromotion.corpId",
    INVOICEDETAIL_ID: "servicePromotion.invoicedetailId",
    SUMMARY_ID: "servicePromotion.summaryId",
    ADDITION_DEDUCTION_IND: "servicePromotion.additionDeductionInd",
    ADDITION_DEDUCTION_CODE: "servicePromotion.additionDeductionCode",
    AMOUNT_RATE_PERCENT: "servicePromotion.amountRatePercent",
    ADDITION_DEDUCTION_TYPE: "servicePromotion.additionDeductionType",
    EXPLANATION: "servicePromotion.explanation",
    MOD_DATE_TIME: "servicePromotion.modDateTime"
  },
  InvTradeParty: {
    TITLE: "invTradeParty.title",
    ID: "invTradeParty.id",
    CORP_ID: "invTradeParty.corpId",
    INVOICE_ID: "invTradeParty.invoiceId",
    PARTY_ID: "invTradeParty.partyId",
    PARTY_NAME: "invTradeParty.partyName",
    IDENTIFICATION_CODE: "invTradeParty.identificationCode",
    FDA_REG_NUM: "invTradeParty.fdaRegNum",
    ADDRESS: "invTradeParty.address",
    CITY: "invTradeParty.city",
    COUNTRY_CODE: "invTradeParty.countryCode",
    STATE_CODE: "invTradeParty.stateCode",
    POSTAL_CODE: "invTradeParty.postalCode",
    CONTACT_NAME: "invTradeParty.contactName",
    CONTACT_TELPH_NUM: "invTradeParty.contactTelphNum",
    CONTACT_FAX: "invTradeParty.contactFax",
    CONTACT_EMAIL: "invTradeParty.contactEmail",
    PARTY_TYPE: "invTradeParty.partyType",
    DBA: "invTradeParty.dba",
    PARTYIDENTIFIER: "invTradeParty.partyidentifier",
    SECOND_IDENTIFICATION_CODE: "invTradeParty.secondIdentificationCode",
    AUTOFILL: "invTradeParty.autoFill",
    PARTYID: "invTradeParty.inputPartyID",
    PARTY_ADDRESS: "invTradeParty.partyAddress",
    NOTE_LABEL:"invTradeParty.noteLabel",
    NOTE_TRADE_PARTY:"invTradeParty.noteTradeParty",
    MOD_DATE_TIME: "invTradeParty.modDateTime",
    SAVE_FLAG:"invTradeParty.saveFlag",
    UPSSCS:"invTradeParty.upsScs"
  },
  CiForm: {
    TITLE: "ciForm.title",
    ID: "ciForm.id",
    CORP_ID: "ciForm.corpId",
    INVOICE_ID: "ciForm.invoiceId",
    COUNTRY_TRANS_SHIPMENT: "ciForm.countryTransShipment",
    COUNTRY_OF_ORIGIN: "ciForm.countryOfOrigin",
    PLACE_OF_DIRECT_SHIPMENT: "ciForm.placeOfDirectShipment",
    ALTERNATIVE_INV_NO: "ciForm.alternativeInvNo",
    CUSTOMS_RULING: "ciForm.customsRuling",
    TRANSPORT_TERM_CODE: "ciForm.transportTermCode",
    TERM_OF_SALE: "ciForm.termOfSale",
    ROYALITY_PAYMENTS: "ciForm.royalityPayments",
    ROYALITY_GOODS: "ciForm.royalityGoods",
    MOD_DATE_TIME: "ciForm.modDateTime"
  },
  CiCargoSummary: {
    TITLE: "ciCargoSummary.title",
    CI_FORM: "ciCargoSummary._ciFormId",
    ID: "ciCargoSummary.id",
    CORP_ID: "ciCargoSummary.corpId",
    CI_FORM_ID_REF: "ciCargoSummary.ciFormIdRef",
    REFERENCE: "ciCargoSummary.reference",
    REFERENCE_NUMBER: "ciCargoSummary.referenceNumber",
    MOD_DATE_TIME: "ciCargoSummary.modDateTime"
  },
  CfiaForm: {
    TITLE: "cfiaForm.title",
    ID: "cfiaForm.id",
    CORP_ID: "cfiaForm.corpId",
    INVOICE_ID: "cfiaForm.invoiceId",
    ETA_DATE_TIME: "cfiaForm.etaDateTime",
    CBSA_RELEASE_OFF: "cfiaForm.cbsaReleaseOff",
    DELIVERY_ADDRESS: "cfiaForm.deliveryAddress",
    MEAT_SHIPMENT: "cfiaForm.meatShipment",
    UNMARK_MEAT_SHIP: "cfiaForm.unmarkMeatShip",
    QUANTITY: "cfiaForm.quantity",
    DRIVER_WAITING: "cfiaForm.driverWaiting",
    IS_EDI: "cfiaForm.isEdi",
    TRANSACTION_NO: "cfiaForm.transactionNo",
    PROD_DESC: "cfiaForm.prodDesc",
    VOYAGE_NO: "cfiaForm.voyageNo",
    ENTRY_POINT: "cfiaForm.entryPoint",
    BL_NUMBER: "cfiaForm.blNumber",
    COMMENTS: "cfiaForm.comments",
    PAYER_NAME: "cfiaForm.payerName",
    CFIA_CLIENT_ACCT_NO: "cfiaForm.cfiaClientAcctNo",
    SEND_ADDRESS_IND: "cfiaForm.sendAddressInd",
    MOD_DATE_TIME: "cfiaForm.modDateTime"
  },
  ImportDeclForm: {
    TITLE: "importDeclForm.title",
    ID: "importDeclForm.id",
    CORP_ID: "importDeclForm.corpId",
    INVOICE_ID: "importDeclForm.invoiceId",
    TRANSACTION_NO: "importDeclForm.transactionNo",
    CONTAINER_NO1: "importDeclForm.containerNo1",
    CONTAINER_NO2: "importDeclForm.containerNo2",
    CONTAINER_NO3: "importDeclForm.containerNo3",
    FLIGHT_NO: "importDeclForm.flightNo",
    TRAILER_NO: "importDeclForm.trailerNo",
    IS_DAIRY: "importDeclForm.isDairy",
    IS_PROCESS_FOOD_VEG: "importDeclForm.isProcessFoodVeg",
    IS_HONEY: "importDeclForm.isHoney",
    IS_MAPLE_PROD: "importDeclForm.isMapleProd",
    IS_SEEDS: "importDeclForm.isSeeds",
    IS_FEED: "importDeclForm.isFeed",
    IS_FORTIZER: "importDeclForm.isFortizer",
    MOD_DATE_TIME: "importDeclForm.modDateTime"
  },
  ProdDetails: {
    TITLE: "prodDetails.title",
    IMPORT_DECL_FORM: "prodDetails._importDeclFormId",
    ID: "prodDetails.id",
    CORP_ID: "prodDetails.corpId",
    IMPORT_ID: "prodDetails.importId",
    PURCHASE_ORDER_NO: "prodDetails.purchaseOrderNo",
    COMMON_NAME: "prodDetails.commonName",
    BRAND_NAME: "prodDetails.brandName",
    GRADE_CATEGORY: "prodDetails.gradeCategory",
    NO_OF_CONTAINERS: "prodDetails.noOfContainers",
    MOD_DATE_TIME: "prodDetails.modDateTime"
  },
  ContDetails: {
    TITLE: "contDetails.title",
    IMPORT_DECL_FORM: "contDetails._importDeclFormId",
    ID: "contDetails.id",
    CORP_ID: "contDetails.corpId",
    IMPORT_ID: "contDetails.importId",
    TYPE_NET_CONTENT: "contDetails.typeNetContent",
    QUANTITY: "contDetails.quantity",
    LABEL_NO: "contDetails.labelNo",
    IMPORT_PURPOSE: "contDetails.importPurpose",
    DOCUMENTATION: "contDetails.documentation",
    REGISTRATION_NO: "contDetails.registrationNo",
    MOD_DATE_TIME: "contDetails.modDateTime"
  },
  Validations: {
    ERR_MSG_ALPHABET: "validations.errMsgAlphabet",
    ERR_MSG_ALPHA_NUMERIC: "validations.errMsgAlphaNumeric",
    ERR_MSG_ALPHA_NUMERIC_SLASH: "validations.errMsgAlphaNumericSlash",
    ERR_MSG_ALPHA_NUMERIC_HYPHEN_DOT: "validations.errMsgAlphaNumericHyphenDot",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR: "validations.errMsgAlphaNumericSpecChar",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR2: "validations.errMsgAlphaNumericSpecChar2",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_COMA_DOT_DASH: "validations.errMsgAlphaNumericSpecCharComaDotDash",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_PHONE_FAX: "validations.errMsgAlphaNumericSpecCharPhoneFax",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_COMA_DOT_DASH_UNDERSCORE: "validations.errMsgAlphaNumericSpecCharComaDotDashUnderscore",
    ERR_MSG_NUMERIC: "validations.errMsgNumeric",
    ERR_MSG_NUMERIC_DECIMAL: "validations.errMsgNumericDecimal",
    ERR_MSG_NUMERIC_HYPHEN_SPACE: "validations.errMsgNumericHyphenSpace"
  }
}

export default NBCICanadaInvoiceKeys
