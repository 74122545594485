const NBCIUSPartyMasterKeys = {
  TITLE: "title",
  USORCA_MASTER:"usorcaMaster",
  ACCT_I_D: "acctID",
  PARTY_I_D: "partyID",
  PARTY_NAME: "partyName",
  D_B_A: "dBA",
  PARTY_TYPE: "partyType",
  NOTE_LABEL:"noteLabel",
  NOTE:"note",
  NOTE2:"note2",
    ADDRESSTITLE: "addresstitle",
    ADDRESS1: "address1",
    ADDRESS2: "address2",
    ADDRESS3: "address3",
    CITY: "city",
    STATE: "state",
    COUNTRY: "country",
    POSTAL_CODE: "postalCode",
    CONTACT_PERSON: "contactPerson",
    TEL_NUMBER: "telNumber",
    FAX: "fax",
    EMAIL: "email",
    CUSTTITLE: "custtitle",
    SCAC_CODE: "scacCode",
    C_PAT: "cPat",
    PartyIdentifierSection: {
    TITLE: "partyIdentifierSection.title",
    PARTY_IDENTIFIER_CODE: "partyIdentifierSection.partyIdentifierCode",
    PARTY_IDENTIFIER_NUMBER: "partyIdentifierSection.partyIdentifierNumber"
  },
  Validations: {
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR: "validations.errMsgAlphaNumericSpecChar",
    ERR_MSG_ALPHA_NUMERIC_HYPHEN_UNDERSCORE: "validations.errMsgAlphaNumericHyphenUnderscore",
    ERR_MSG_ALPHA_NUMERIC_FAX: "validations.errMsgAlphaNumericFax"
  }
}

export default NBCIUSPartyMasterKeys
