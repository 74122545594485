const DataRestoreKeys = {
    TITLE: "title",
    MODULE: "module",
    TYPE: "type",
    RESTORE_BY: "restoreBy",
    DATE_FROM: "dateFrom",
    DATE_TO: "dateTo",
    BOND_NUMBER: "bondNumber",
    TRIP_NUMBER: "tripNumber",
    SHIPMENT_CONTROL_NUMBER: "shipmentControlNumber",
    DAY_TO_RETAIN: "dayToRetain",
    USER_ID: "userId",
    BUTTON: "button",
    HELP_TEXT_1: "helpText1",
    ERROR_MESSAGE: "errorMessage",
    SUBMIT_BUTTON: "submitButton",
    ERROR_MESSAGE_TPR_USER: "errorMessageTPRUser",
    ERROR_MESSAGE_TPR_USER_PREFERENCE: "errorMessageTPRUserPreference",
    SUCCESS_MESSAGE: "successMessage",
    PRIMARY_CCN: "primaryCCN",
    PREVIOUS_CCN: "previousCCN",
    CCN: "ccn",
    CBSA_REF_NO: "cbsaRefNo",
    SCAC: "scac",
    VESSEL_NAME: "vesselName",
    VOYAGE_NUMBER: "voyageNumber",
    PORT_OF_DISCHARGE: "portOfDischarge",
    ETA: "eta",
    ERROR_MESSAGE_FILE_FOR_USER_PROFILE: "errorMessageFileForUserProfile",
    ERROR_MESSAGE_FILE_FOR_USER_CORP_ID: "errorMessageFileForUserCorpId",
    ERROR_MESSAGE_LOGGED_IN_USER_CORP_ID: "errorMessageLoggedInUserCorpId",
    ERROR_MESSAGE_LOGGED_IN_USER_PROFILE: "errorMessageLoggedInUserProfile",
    ERROR_MESSAGE_RETRIEVE_DATA: "errorMessageRetrieveData",
    ERROR_MESSAGE_CORP_ID_NOT_MATCH: "errorMessageCorpIdNotMatch",
    ERROR_MESSAGE_CHILD_CORP_ID_NOT_MATCH : "errorMessageChildCorpIdNotMatch",
    ERROR_MESSAGE_SERVICE_BUREAU_FLAG: "errorMessageServiceBureauFlag",
    ERROR_MESSAGE_SERVICE_BUREAU_CHILD_CORP_ID_FLAG: "errorMessageServiceBureauChildCorpIdFlag",
    ERROR_MESSAGE_TPR_USER_DETAILS: "errorMessageTPRUserDetails",
    ERROR_MESSAGE_TPR_USER_DETAILS_INACTIVE: "errorMessageTPRUserDetailsInactive",
    Validations: {
        ERROR_MSG_MAX_LENGTH : "validations.errMsgMaxLength"
    }
}

export default DataRestoreKeys