const UsProductMasterKeys = {
  TITLE: "title",
  UPLOAD: "upload",
  DOC_REF_NO: "docRefNo",
  PRODUCT_ID: "productId",
  MANUFACTURING_PART_NO: "manufacturingPartNo",
  ITEM_DESCRIPTION: "itemDescription",
  PRODUCT_CLASSIFICATION_CODE: "productClassificationCode",
  COUNTRY_OF_ORIGIN: "countryOfOrigin",
  UOM: "uom",
  UNIT_PRICE: "unitPrice",
  TARIFF_NO: "tariffNo",
  MARK_PACK_LOADING: "markPackLoading",
  USMCA_LABEL: "usmcaLabel",
  INCLUDE_PRODUCT: "includeProduct",
  IS_PRODUCER: "isProducer",
  NOT_A_PRODUCER_OF_GOODS: "notAProducerOfGoods",
  EXPORTER_LABEL_ONE: "exporterLabel1",
  EXPORTER_LABEL_TWO: "exporterLabel2",
  EXPORTER_LABEL_THREE: "exporterLabel3",
  NAFTA_CRITERIA: "naftaCriteria",
  NET_COST: "netCost",
  DATE_RANGE_FROM: "dateRangeFrom",
  DATE_RANGE_TO: "dateRangeTo",
  MANUFACTURING_LABEL: "manufacturingLabel",
  MANUFACTURING_PARTY_NAME: "manufacturingPartyName",
  MANUFACTURING_M_I_D: "manufacturingMID",
  MANUFACTURING_F_D_A_REG_NUM: "manufacturingFDARegNum",
  GROWER_LABEL: "growerLabel",
  GROWER_PARTY_NAME: "growerPartyName",
  GROWER_M_I_D: "growerMID",
  GROWER_F_D_A_REG_NUMBER: "growerFDARegNumber",
  CONSOLIDATOR_LABEL: "consolidatorLabel",
  CONSOLIDATOR_PARTY_NAME: "consolidatorPartyName",
  CONSOLIDATOR_M_I_D: "consolidatorMID",
  CONSOLIDATOR_F_D_A_REG_NUMBER: "consolidatorFDARegNumber",
  CLONE_BUTTON: "Clone",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  NOTE: "note",
  NOTE_LABEL: "noteLabel",
  CLICK_HERE: "clickHere",
  USORCA_MASTER: "usorcaMaster",
  HS_CODE: "hsCode",
  Validations: {
    ERR_MSG_ALPHA_NUMERIC_HYPHEN_UNDERSCORE: "validations.errMsgAlphaNumericHyphenUnderscore",
    ERR_MSG_ALPHA_NUMERIC: "validations.errMsgAlphaNumeric",
    ERR_MSG_ALPHA_NUMERIC_PARTNO: "validations.errMsgAlphaNumericPartNo",
    ERR_MSG_ALPHA_NUMERIC_ITEM_DESC: "validations.errMsgAlphaNumericItemDesc",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR: "validations.errMsgAlphaNumericSpecChar"
  }
}

export default UsProductMasterKeys
