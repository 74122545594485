const TermsOfUse = {
    TERMS_OF_USE: "termsOfUse",
    GENERAL: "general",
    GENERAL_DETAILS1: "generalDetails1",
    GENERAL_DETAILS2: "generalDetails2",
    TERMINOLOGY: "terminology",
    TERMINOLOGY_DETAILS1: "terminologyDetails1",
    DISCLAIMER_AND_LIABILITY:"disclaimerAndLiability",
    DISCLAIMER_AND_LIABILITY_DETAILS1: "disclaimerAndLiabilityDetails1",
    DISCLAIMER_AND_LIABILITY_DETAILS2: "disclaimerAndLiabilityDetails2",
    DISCLAIMER_AND_LIABILITY_DETAILS3: "disclaimerAndLiabilityDetails3",
    DISCLAIMER_AND_LIABILITY_DETAILS4: "disclaimerAndLiabilityDetails4",
    EXCEPTION_TO_DISCLAIMER: "exceptionToDisclaimer",
    EXCEPTION_TO_DISCLAIMER_DETAILS1: "exceptionToDisclaimerDetails1",
    COPYRIGHT: "copyright",
    COPYRIGHT_DETAILS1: "copyrightDetails1",
    COPYRIGHT_DETAILS2: "copyrightDetails2",
    COPYRIGHT_DETAILS3: "copyrightDetails3",
    TRADEMARKS: "trademarks",
    TRADEMARKS_DETAILS1: "trademarksDetails1",
    RESTRICTED_USE: "restrictedUse",
    RESTRICTED_USE_DETAILS1 : "restrictedUseDetails1",
    LINKED_WEB_SITES: "linkedWebSites",
    LINKED_WEB_SITES_DETAILS1:"linkedWebSitesDetails1",
    LINKED_WEB_SITES_DETAILS2:"linkedWebSitesDetails2",
    LINKED_WEB_SITES_DETAILS3:"linksFromOtherWebSites",
    LINKS_FROM_OTHER_WEBSITES :"linksFromOtherWebSites",
    LINKS_FROM_OTHER_WEBSITES_DETAILS1:"linksFromOtherWebSitesDetails1",
    AVAILABILITY_AND_TERMINATION_OF_ACCESS:"availabilityAndTerminationOfAccess",
    AVAILABILITY_AND_TERMINATION_OF_ACCESS_DETAILS1:"availabilityAndTerminationOfAccessDetails1",
    AVAILABILITY_AND_TERMINATION_OF_ACCESS_DETAILS2:"availabilityAndTerminationOfAccessDetails2",
    GOVERNING_LAW:"governingLaw",
    GOVERNING_LAW_DETAILS1:"governingLawDetails1",
    VERSION:"version"

  }
  
export default TermsOfUse
  